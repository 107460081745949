
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import moment from "moment";
import * as api from "@/api/videoInspection";

@Options({})
export default class extends Vue {
  @Prop({ default: false }) readonly show!: boolean;
  @Prop({ default: "" }) readonly deviceNo!: string;
  refreshing = false;
  checkList: any[] = [];
  loading = false;
  finished = false;
  result: any = [];
  timeDate: any = {};

  public closePopup() {
    this.$emit("close", "1");
  }

  public onLoad() {
    setTimeout(() => {
      if (this.refreshing) {
        this.checkList = [];
        this.refreshing = false;
      }
      api
        .getDeviceVideoByTime({
          deviceNo: this.deviceNo,
          startTime: moment().add(-7, "d").format("YYYY-MM-DD 00:00:00"),
          endTime: moment().format("YYYY-MM-DD HH:mm:ss"),
        })
        .then((res) => {
          console.log(res.data);

          this.checkList = res.data.map((i: any) => {
            return {
              ...i,
              flag: false,
            };
          });
          localStorage.setItem("arr", "");
          this.loading = false;
          this.finished = true;
        });
    }, 500);
  }

  public onFinish() {
    this.$emit("close", this.timeDate);
  }

  public onRefresh() {
    this.finished = false;
    this.loading = true;
    this.onLoad();
  }

  public chanageList(val: any) {
    const loc: any = localStorage.getItem("arr");
    const oldArr = loc ? JSON.parse(loc) : [];

    const arr: any = [];
    if (val.length > 1) {
      this.checkList.forEach((i, index) => {
        val.find((j: any) => {
          if (i.fileName === j) {
            arr.push({
              ...i,
              index: index,
            });
          }
        });
      });

      const firstTime = arr[0];
      const lastTime = arr[arr.length - 1];
      if (oldArr.length > val.length) {
        const num = oldArr.map((m: any) => {
          return val.indexOf(m);
        });

        if (num[0] === -1) {
          this.result = [lastTime.fileName];
          val = [lastTime.fileName];
        } else {
          this.result = [firstTime.fileName];
          val = [firstTime.fileName];
        }

        this.checkList.forEach((k, index) => {
          k.flag = false;
        });
      } else {
        this.checkList.forEach((k, index) => {
          if (firstTime.index < index && index < lastTime.index) {
            if (val.indexOf(k.fileName) === -1) {
              val.push(k.fileName);
            }
            k.flag = true;
          }
          this.checkList[firstTime.index].flag = false;
          this.checkList[lastTime.index].flag = false;
        });
      }

      const arr1: string[] | null = [];
      const arr2: any[] | null = [];

      this.checkList.forEach((i) => {
        val.find((j: any) => {
          if (i.fileName === j) {
            arr1.push(i.fileName);
            arr2.push(i);
          }
        });
      });
      this.timeDate = {
        videoStartTime: arr2[0].fileStartTime,
        videoEndTime: arr2[arr2.length - 1].fileEndTime,
      };

      localStorage.setItem("arr", JSON.stringify(arr1));
    } else {
      this.checkList.forEach((i) => {
        val.find((j: any) => {
          if (i.fileName === j) {
            this.timeDate = {
              videoStartTime: i.fileStartTime,
              videoEndTime: i.fileEndTime,
            };
          }
        });
      });
    }
  }
}
