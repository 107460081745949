
import { Options, Vue, Watch } from "vue-property-decorator";
import * as api from "@/api/videoInspection";
import responsibilityperson from "./responsibility.vue";
import { parseTime } from "@/utils";
import moment from "moment";
import Video from "./video.vue";

@Options({
  components: {
    responsibilityperson,
    Video,
  },
})
export default class extends Vue {
  resultPicker = false;
  completeTimePicker = false;
  flag = false;
  checkId: any = "";
  videoShow = false;
  currentDate = new Date();
  resultPickerscolumns = [
    { id: 1, name: "正常" },
    { id: 2, name: "异常" },
  ];
  resultText = null;
  rectifyCompleteTime: any = null;
  goBack() {
    this.$router.go(-1);
  }
  showEnd = false;
  form: any = {
    title: null,
    checkTypeText: null,
    deviceName: null,
    startTime: null,
    endTime: null,
    timeCount: null,
    checkerName: null,
    description: "",
  };
  submitform: any = {
    endTime: null,
    checkId: null,
    result: null,
    rectifyId: null,
    rectifyName: null,
    deviceNo: null,
    rectifyCompleteTime: null,
  };
  deviceNo = null;
  minDate = new Date(2022, 0, 1);
  time: any = null;
  show = false;
  @Watch("videoShow")
  videShowChange(newValue: string, oldValue: string) {
    if (newValue) {
      this.timeOut();
    } else {
      clearInterval(this.time);
    }
  }
  mounted() {
    this.checkId = this.$route.query.id;
    this.initareaIds();
  }
  timeOut() {
    this.time = setInterval(() => {
      api.getVideoCheckDetail({ checkId: this.checkId }).then((res: any) => {
        if (res.code === 0) {
          if (res.data.checkVideo) {
            this.showEnd = true;
          } else {
            this.showEnd = false;
          }
        } else {
          this.$toast.fail(res.data.msg);
        }
      });
    }, 10000);
  }
  closeToBack() {
    this.videoShow = false;
    this.$toast("视频上传成功");
    this.$router.push({
      path: "/app/videoInspection",
    });
  }

  initareaIds() {
    api
      .getVideoCheckDetail({
        checkId: this.checkId,
      })
      .then((res: any) => {
        if (res.code === 0) {
          this.form = res.data;
          this.deviceNo = res.data.deviceNo;
          this.submitform = {
            endTime: null,
            checkId: this.checkId,
            result: res.data.result,
            rectifyId: null,
            rectifyName: null,
            deviceNo: res.data.deviceNo,
            rectifyCompleteTime: null,
          };
        } else {
          this.$toast.fail(res.msg);
        }
      });
  }

  formatter = (type: any, val: any) => {
    if (type === "year") {
      return `${val}年`;
    }
    if (type === "month") {
      return `${val}月`;
    }
    return val;
  };

  public selectPerson(val: any) {
    this.submitform.rectifyId = val;
  }

  public selectPersonName(val: any) {
    this.submitform.rectifyName = val;
  }

  public resultPickersonConfirm(val: any) {
    this.submitform.result = val.id;
    this.resultText = val.name;
    this.resultPicker = false;
  }

  public completeTimePickerConfirm(val: any) {
    this.rectifyCompleteTime = parseTime(val, "{y}年{m}月{d}日");
    this.submitform.rectifyCompleteTime = parseTime(val, "{y}-{m}-{d}");
    this.completeTimePicker = false;
  }

  closeVideo(data: any) {
    if (data !== "1") {
      const formdata = {
        ...this.submitform,
        ...data,
      };
      api.stopVideoCheck(formdata).then((res: any) => {
        if (res.code === 0) {
          this.videoShow = true;
          // this.$router.push({
          //   path: "/app/videoInspection",
          // });
          // this.$toast.fail("成功");
        } else {
          this.$toast.fail(res.msg);
        }
      });
    }
    this.show = false;
  }

  onSubmit() {
    console.log(moment().format("YYYY-MM-DD HH:mm:ss"));

    const endtime = moment().format("yyyy-MM-DD HH:mm:ss");
    const duration = moment(endtime).diff(
      moment(this.form.startTime),
      "minutes"
    );
    this.form.endTime = endtime;

    this.form.timeCount = duration.toString() + " 分钟";
    this.submitform.endTime = endtime;

    this.show = true;
  }
}
